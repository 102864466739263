:root {
  --text-primary: '#000000';
  --background-default: '#fdfffc';
}

html[theme="dark"] {
  --text-primary: '#ffffff';
  --background-default: #121212;
}

.page {
  display: flex;
  flex-direction: column;
  padding-bottom: 90px;
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.page::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
}

main {
  flex-grow: 1;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.react-calendar {
  margin: 0 auto;
  padding: 5px;
  width: 100%;
  border-radius: 8px;
  border-color: rgba(0, 0, 0, 0.2);
  background: var(--background-default);
}

.react-calendar__tile {
  position: relative;
  transition: all 0.2s ease-in;
}

.react-calendar__tile.has-notes::before {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #1976d2;
}

.react-calendar__tile--active {
  background: #1976d2 !important;
  border-radius: 8px;
  color: #ffffff !important;
}

.react-calendar__tile--active.has-notes::before {
  background-color: #ffffff;
}

.react-calendar__tile--now {
  background: none;
  border: 1px solid #1976d2 !important;
  border-radius: 8px;
}

.react-calendar__tile:enabled:hover {
  border-radius: 8px;
  color: #000000;
}

.react-calendar__tile--now:enabled:hover {
  opacity: 0.7;
  background: none;
  border-radius: 8px;
}

.react-calendar__tile:disabled {
  background: none;
  opacity: 0.3;
}

.react-calendar__month-view__days__day {
  color: var(--text-primary);
}

.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0.7;
}

.react-calendar__month-view__days__day.react-calendar__tile--active {
  color: #ffffff;
}

.react-calendar__navigation button:disabled {
  background: none;
  opacity: 0.3;
}

.react-calendar__navigation__label {
  border-radius: 8px;
  color: var(--text-primary);
  transition: background 0.2s ease-in;
}

.react-calendar__navigation__arrow {
  border-radius: 8px;
  color: var(--text-primary);
  transition: background 0.2s ease-in;
}

.react-calendar__navigation button:enabled:hover {
  background-color: rgba(25, 118, 210, 0.1);
}

.react-calendar__month-view__weekdays {
  margin-bottom: 10px;
}

.react-calendar__month-view__weekdays__weekday {
  background-color: rgba(25, 118, 210, 0.1);
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}
